import { isMobile } from "react-device-detect";
import { DropdownMenuItem } from "../ui/dropdown";
import { FIELD_ICONS, Fields, FilterFields } from "./fields";
import { CommandItem } from "../ui/command";
import { SearchRuleGroupType } from "./types";

interface FilterButtonProps {
  field: (typeof Fields)[number];
  addFilter: (rule: SearchRuleGroupType["rules"][number]) => void;
}
export const AvailableFilter = ({ field, addFilter }: FilterButtonProps) => {
  const onFilterClick = () => {
    const { name } = field;
    switch (name) {
      case FilterFields.TagID:
      case FilterFields.MentionID:
      case FilterFields.PageID:
      case FilterFields.Title:
      case FilterFields.TaskStatus:
      case FilterFields.NodeSource:
      case FilterFields.NodeType:
        addFilter({
          id: name,
          combinator: "and",
          rules: [],
        });
        break;
      case FilterFields.CreatedAt:
      case FilterFields.ModifiedAt:
        // eslint-disable-next-line no-case-declarations
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        addFilter({
          id: name,
          field: name,
          operator: "=",
          value: "",
        });
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = FIELD_ICONS[field.name] as (() => JSX.Element) | undefined;

  if (isMobile) {
    return (
      <CommandItem onSelect={onFilterClick} className="gap-2 mt-2">
        {Icon && <Icon />}
        {field.label}
      </CommandItem>
    );
  }

  return (
    <DropdownMenuItem onClick={onFilterClick} className="gap-2">
      {Icon && <Icon />}
      {field.label}
    </DropdownMenuItem>
  );
};
