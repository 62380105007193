import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Calendar, CalendarProps } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/utils/styles";
import { isMobile } from "react-device-detect";

interface DatePickerProps {
  date: Date | undefined;
  setDate: (date: Date) => void;
  calendarProps?: CalendarProps;
  popover?: boolean;
  contentOnly?: boolean;
}
export function DatePicker({
  date,
  setDate,
  calendarProps,
  popover = true,
  contentOnly,
}: DatePickerProps) {
  const calendar = (
    <Calendar
      {...calendarProps}
      mode="single"
      selected={date}
      onSelect={(e) => {
        if (!e) return;
        setDate(e);
      }}
      initialFocus
    />
  );

  if (!popover) return calendar;

  return (
    <Popover>
      <PopoverTrigger asChild disabled={contentOnly}>
        <Button
          variant={"outline"}
          className={cn(
            !isMobile && "w-[280px]",
            "h-[24px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">{calendar}</PopoverContent>
    </Popover>
  );
}
