import clsx from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { range, sortBy } from "lodash";
import { NavLink } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { EfNodeType } from "../graphql";
import { btn } from "../styles/classes";
import { EfNode, EfNodeEditorData } from "../types";
import { getParentPageNode } from "../utils/getParentPageNode";
import { GoToNodeIcon } from "./Icons";
import { Skeleton } from "./ui/skeleton";
import { useEffect, useState } from "react";
import { SingleNodeEditor } from "./VirtualizedEditor/SingleNodeEditor";
import { useIosKeyboardHeight } from "@/hooks/useIosKeyboardHeight";
import { BOTTOM_TOOLBAR_HEIGHT } from "./VirtualizedEditor/MobileToolbar/MobileToolbar";
import { createNode } from "@/utils";
import { useKeyboardShortcut } from "@/hooks/useKeyboardShortcut";

export const NodesGroupedByPageSkeleton = ({ length }: { length: number }) => {
  return (
    <div className="space-y-5">
      <Skeleton className="h-10 w-full " />
      {range(1, length).map((_, idx) => {
        return (
          <div className="flex items-center space-x-4" key={idx}>
            <Skeleton className="h-6 w-6 rounded-full" />
            <div className="space-y-2 flex-1 flex">
              <div className="w-full flex flex-row">
                <Skeleton className="rounded-full w-3 h-3" />
                <span className="flex-1 ml-2">
                  <Skeleton className="h-4 w-full " />
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export function NodesGroupedByPage({
  nodes,
  showSkeleton = false,
  createNewNodeAtStart,
  tagsOrMentions = [],
  containerScrollRef,
  headerRef,
}: {
  nodes: EfNode[];
  hideChildren?: boolean;
  showSkeleton?: boolean;
  createNewNodeAtStart?: () => Promise<EfNodeEditorData | undefined>;
  tagsOrMentions?: string[];
  containerScrollRef?: React.RefObject<HTMLElement>;
  headerRef: React.RefObject<HTMLDivElement>;
}) {
  const groupResult = useLiveQuery(() => groupNodesByPage(nodes), [nodes]);
  const [activeNode, setActiveNode] = useState<string | null>(null);
  const mobileKeyboardHeight = useIosKeyboardHeight();

  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: ["Escape"] }],
    element: window,
  });

  useEffect(() => {
    const createNewNode = async () => {
      const newNodeToCreate = await createNewNodeAtStart?.();
      if (!newNodeToCreate) {
        return;
      }
      await createNode(newNodeToCreate);
      setActiveNode(newNodeToCreate.id);
    };
    if (isKeyboardShortcut) {
      createNewNode();
    }
  }, [isKeyboardShortcut]);

  if (!groupResult)
    return showSkeleton ? <NodesGroupedByPageSkeleton length={20} /> : null;

  const pages = sortNodes(Object.values(groupResult.pagesById));

  return (
    <div
      data-testid={DataTest.GroupedNodesList}
      style={{
        paddingBottom: mobileKeyboardHeight + BOTTOM_TOOLBAR_HEIGHT,
      }}
    >
      {pages.map((page) => {
        const pageRoute =
          page.nodeType === EfNodeType.ThoughtPad
            ? `/thoughtpad`
            : `/pages/${page.id}`;
        return (
          <div key={page.id} className="mb-6 divide-y pb-0 border rounded-md">
            <NavLink to={pageRoute} className={clsx(btn, "rounded-b-none")}>
              {page.titleText}
            </NavLink>
            {sortNodes(groupResult.nodesByPageId[page.id]).map((node) => {
              const createNewEmptyNode = !!tagsOrMentions.find(
                (tagOrMention) =>
                  (node.tagIds || []).includes(tagOrMention) ||
                  (node.mentionIds || []).includes(tagOrMention)
              );
              return (
                <div
                  key={node.id}
                  className="p-1 flex"
                  data-testid={DataTest.GroupedNodesListItem}
                >
                  <div className="add-padding flex items-start ">
                    <NavLink
                      to={`${pageRoute}?node=${node.id}`}
                      className="p-1.5 hover:bg-gray-200 rounded-full"
                    >
                      <GoToNodeIcon className="w-5 h-5" />
                    </NavLink>
                  </div>
                  <div className="w-full">
                    <SingleNodeEditor
                      parentNode={node}
                      isActive={activeNode === node.id}
                      setActive={setActiveNode}
                      createNewEmptyNode={createNewEmptyNode}
                      headerRef={headerRef}
                      containerScrollRef={containerScrollRef}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function sortNodes(nodes: EfNode[]) {
  return sortBy(nodes, "position");
}

async function groupNodesByPage(rawNodes: EfNode[]) {
  const pagesById: Record<string, EfNode> = {};
  const nodesByPageId: Record<"no-page" | (string & {}), EfNode[]> = {
    "no-page": [],
  };

  for (const node of rawNodes) {
    const page = await getParentPageNode(node.id);
    const pageId = page?.id ?? "no-page";

    if (page) {
      pagesById[page.id] = page;
    }

    if (!nodesByPageId[pageId]) {
      nodesByPageId[pageId] = [];
    }
    if (!node.deleted) nodesByPageId[pageId].push(node);
  }

  return { nodesByPageId, pagesById };
}
