import { isRuleGroup, RuleGroupType } from "react-querybuilder";
import {
  AllAnyOperators,
  allAnyOperatorToCombinator,
  combinatorToAllAnyOperator,
} from "./utils";
import { useMemo } from "react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { ChevronDown, X } from "lucide-react";
import { OperatorDropdown } from "../OperatorDrowdown";
import { FilterCommand } from "./FilterCommand";
import {
  FilterContainer,
  FilterItemsContainer,
  MobileFilterContainer,
  MobileFilterOperatorContainer,
} from "./FilterContainer";
import { Button } from "@/components/ui/button";
import { useSearch } from "@/context/SearchContext";
import { DropdownMenuContent } from "@/components/ui/dropdown";
import { cn } from "@/utils/styles";
import { isMobile } from "react-device-detect";
import { SearchRuleGroupType, FilterRule } from "../types";
import { FilterFields } from "../fields";
import {
  ApplyFiltersButton,
  useDirty,
} from "../MobileFilterDrawer/ApplyFilterButton";

interface GenericFilterProps {
  itemRenderer: (itemIds: string[]) => React.ReactElement;
  getItems: (query: string) => Promise<{ label: string; value: string }[]>;
  field:
    | FilterFields.TagID
    | FilterFields.MentionID
    | FilterFields.PageID
    | FilterFields.TaskStatus
    | FilterFields.NodeSource
    | FilterFields.NodeType;
  Icon: () => JSX.Element;
  title: string;
  commandPlaceholder: string;
  dropdown?: boolean;
}

export const GenericFilter = ({
  field,
  itemRenderer,
  getItems,
  Icon,
  commandPlaceholder,
  title,
  dropdown,
}: GenericFilterProps) => {
  const { filters, removeRule, setRule } = useSearch();
  const { dirty } = useDirty(field);
  const rule = useMemo(
    () =>
      filters.rules.find((rule) => rule.id === field) as SearchRuleGroupType,
    [filters, field]
  );

  const operator = combinatorToAllAnyOperator(rule.combinator);
  const activeItems = useMemo(
    () => getActiveItems(rule.rules, field),
    [rule.rules, field]
  );

  const setOperator = (operator: string) => {
    setRule({
      ...rule,
      combinator: allAnyOperatorToCombinator(operator),
    });
  };

  const addItem = (itemId: string) => {
    setRule({
      ...rule,
      rules: [
        ...rule.rules,
        {
          id: itemId,
          field,
          operator: "in",
          value: itemId,
        },
      ],
    });
  };

  const removeItem = (tagId: string) => {
    setRule({
      ...rule,
      rules: [...rule.rules.filter((r) => r.id !== tagId)],
    });
  };

  const operatorDropdownWithTitle = (
    <>
      <div className="flex flex-row items-center gap-1">
        <Icon />
        <span className={cn(isMobile && "text-sm")}>{title}</span>
      </div>
      <OperatorDropdown
        operator={operator}
        setOperator={setOperator}
        operators={AllAnyOperators}
      />
      <span>of</span>
    </>
  );

  if (!dropdown) {
    return (
      <MobileFilterContainer>
        <MobileFilterOperatorContainer>
          {operatorDropdownWithTitle}
          <div className="flex flex-1 justify-end">
            {dirty && <ApplyFiltersButton />}
          </div>
        </MobileFilterOperatorContainer>
        <FilterCommand
          icon={<Icon />}
          selectedItems={activeItems}
          getItems={getItems}
          onSelect={(item) => {
            if (activeItems.includes(item)) {
              removeItem(item);
            } else {
              addItem(item);
            }
          }}
          placeholder={commandPlaceholder}
        />
      </MobileFilterContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterItemsContainer>
        {operatorDropdownWithTitle}
        <DropdownMenuTrigger
          className="flex flex-row items-center"
          disabled={isMobile}
        >
          {itemRenderer(activeItems)}
          <ChevronDown height={16} width={16} />
        </DropdownMenuTrigger>
        <Button
          variant="outline"
          size="icon"
          onClick={() => removeRule(field)}
          className="h-5 w-5 border-0"
        >
          <X height={16} width={16} />
        </Button>
      </FilterItemsContainer>
      <DropdownMenuContent>
        <FilterCommand
          icon={<Icon />}
          selectedItems={activeItems}
          getItems={getItems}
          onSelect={(item) => {
            if (activeItems.includes(item)) {
              removeItem(item);
            } else {
              addItem(item);
            }
          }}
          placeholder={commandPlaceholder}
        />
      </DropdownMenuContent>
    </FilterContainer>
  );
};

const getActiveItems = (
  rules: RuleGroupType<FilterRule, string>["rules"],
  field: FilterFields
) => {
  return rules
    .filter((rule) => !isRuleGroup(rule) && rule.field === field)
    .map((rule) => (rule as FilterRule).value);
};
