import { File } from "../../extensions/File";
import { Mention } from "../../extensions/Mention";
import { PageRef } from "../../extensions/PageRef";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import { Tag } from "../../extensions/Tag";
import StarterKit from "@tiptap/starter-kit";
import { NewSlash } from "../../extensions/new-tiptap/NewSlash";
import { Paste } from "../../extensions/Paste";

export const defaultNewTipTapExtensions = [
  StarterKit.configure({
    history: false,
  }),
  Mention,
  Tag,
  NewSlash,
  File,
  Link.configure({
    HTMLAttributes: {
      class: "link",
    },
  }),
  PageRef,
  Underline,
  Paste.configure({
    newEditor: true,
  }),
];
