import { isRuleGroup, RuleGroupType } from "react-querybuilder";
import { FIELD_ICONS, FilterFields } from "../fields";
import {
  FilterContainer,
  FilterItemsContainer,
  FilterItemValueContainer,
  MobileFilterContainer,
  MobileFilterOperatorContainer,
} from "./FilterContainer";
import { OperatorDropdown } from "../OperatorDrowdown";
import { FilterCommand } from "./FilterCommand";
import { useMemo } from "react";
import { EfNodeType } from "@/graphql";
import { ChevronDown, X } from "lucide-react";
import {
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown";
import { Button } from "@/components/ui/button";
import { useSearch } from "@/context/SearchContext";
import { SearchRuleGroupType, FilterRule } from "../types";
import {
  ApplyFiltersButton,
  useDirty,
} from "../MobileFilterDrawer/ApplyFilterButton";

interface NodeTypeFilterProps {
  dropdown?: boolean;
}
export function NodeTypeFilter({ dropdown = true }: NodeTypeFilterProps) {
  const { filters, removeRule, setRule } = useSearch();
  const { dirty } = useDirty(FilterFields.NodeType);
  const rule = useMemo(
    () =>
      filters.rules.find(
        (rule) => rule.id === FilterFields.NodeType
      ) as SearchRuleGroupType,
    [filters]
  );
  const operator = combinatorToOperator(rule.combinator);
  const activeNodeTypes = useMemo(
    () => getActiveNodeTypes(rule.rules),
    [rule.rules]
  );
  const enableDropdown = operator === NodeTypeOperators.NodeIs;

  const setOperator = (operator: string) => {
    if (operator === NodeTypeOperators.NodeIs) {
      return setRule({
        ...rule,
        combinator: operatorToCombinator(operator),
        rules: rule.rules.length ? [rule.rules[0]] : [],
      });
    }

    return setRule({
      ...rule,
      combinator: operatorToCombinator(operator),
      rules: [
        {
          id: NodeType.Block,
          field: FilterFields.NodeType,
          operator: "=",
          value: NodeType.Block,
        },
        {
          id: NodeType.Task,
          field: FilterFields.NodeType,
          operator: "=",
          value: NodeType.Task,
        },
      ],
    });
  };

  const getNodeTypes = () => {
    return Object.values(NodeType).map((nodeType) => ({
      label: nodeType,
      value: nodeType,
    }));
  };

  const selectNodeType = (nodeType: string) => {
    setRule({
      ...rule,
      rules: [
        {
          id: nodeType,
          field: FilterFields.NodeType,
          operator: "=",
          value: nodeType,
        },
      ],
    });
  };

  const Icon = FIELD_ICONS[FilterFields.NodeType];

  const operatorDropdownWithTitle = (
    <>
      <div className="flex flex-row items-center gap-1">
        <Icon />
        <span>Node Type is</span>
      </div>
      <OperatorDropdown
        operator={operator}
        setOperator={setOperator}
        operators={Operators}
      />
      <span>of</span>
    </>
  );

  if (!dropdown) {
    return (
      <MobileFilterContainer>
        <MobileFilterOperatorContainer>
          {operatorDropdownWithTitle}
          <div className="flex flex-1 justify-end">
            {dirty && <ApplyFiltersButton />}
          </div>
        </MobileFilterOperatorContainer>
        <FilterCommand
          selectedItems={activeNodeTypes}
          getItems={getNodeTypes}
          onSelect={(nodeType) => {
            selectNodeType(nodeType);
          }}
          placeholder="Node Type"
          radio={enableDropdown}
          disabled={!enableDropdown}
        />
      </MobileFilterContainer>
    );
  }

  return (
    <FilterContainer>
      <FilterItemsContainer>
        {operatorDropdownWithTitle}
        <DropdownMenuTrigger className="flex flex-row items-center">
          <NodeTypeRenderer nodeTypes={activeNodeTypes} />
          {enableDropdown && <ChevronDown height={16} width={16} />}
        </DropdownMenuTrigger>
        <Button
          variant="outline"
          size="icon"
          onClick={() => removeRule(FilterFields.NodeType)}
          className="h-5 w-5 border-0"
        >
          <X height={16} width={16} />
        </Button>
      </FilterItemsContainer>
      {enableDropdown && (
        <DropdownMenuContent>
          <FilterCommand
            icon={<Icon />}
            selectedItems={activeNodeTypes}
            getItems={getNodeTypes}
            onSelect={(nodeType) => {
              selectNodeType(nodeType);
            }}
            placeholder="Node Type"
            radio
          />
        </DropdownMenuContent>
      )}
    </FilterContainer>
  );
}

interface NodeTypeProps {
  nodeTypes: string[];
}
const NodeTypeRenderer = ({ nodeTypes }: NodeTypeProps) => {
  return (
    <FilterItemValueContainer>
      {nodeTypes.length === 0 && "No Type Selected"}
      {nodeTypes.length !== 0 && nodeTypes.join(", ")}
    </FilterItemValueContainer>
  );
};

const NodeType = {
  Block: EfNodeType.Block,
  Task: EfNodeType.Task,
};

enum NodeTypeOperators {
  NodeIs = "is",
  NodeIsEither = "is either",
}

const Operators = [
  {
    label: NodeTypeOperators.NodeIs,
    value: NodeTypeOperators.NodeIs,
  },
  {
    label: NodeTypeOperators.NodeIsEither,
    value: NodeTypeOperators.NodeIsEither,
  },
];

const combinatorToOperator = (combinator: string) => {
  if (combinator === "and") {
    return NodeTypeOperators.NodeIs;
  }
  return NodeTypeOperators.NodeIsEither;
};

const operatorToCombinator = (operator: string) => {
  if (operator === NodeTypeOperators.NodeIs) {
    return "and";
  }
  return "or";
};

const getActiveNodeTypes = (
  rules: RuleGroupType<FilterRule, string>["rules"]
) => {
  return rules
    .filter(
      (rule) => !isRuleGroup(rule) && rule.field === FilterFields.NodeType
    )
    .map((rule) => (rule as FilterRule).value);
};
