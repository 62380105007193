import { useLiveQuery } from "dexie-react-hooks";
import { isEmpty, sortBy } from "lodash";
import { NavLink } from "react-router-dom";
import { DataTest } from "../../tests/e2e/utils/constants";
import { EfNodeType } from "../graphql";
import { EfNode, EfNodeEditorData } from "../types";
import { getParentPageNode } from "../utils/getParentPageNode";
import { usePrevious } from "../hooks/usePrevious";
import { useEffect, useMemo, useState } from "react";
import { GoToNodeIcon } from "./Icons";
import { SingleNodeEditor } from "./VirtualizedEditor/SingleNodeEditor";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { createNode } from "@/utils";
import { useKeyboardShortcut } from "@/hooks/useKeyboardShortcut";
import { BOTTOM_TOOLBAR_HEIGHT } from "./VirtualizedEditor/MobileToolbar/MobileToolbar";
import { useIosKeyboardHeight } from "@/hooks/useIosKeyboardHeight";
import { filterNodesBasedOnArray } from "@/utils/nodes";

export function ChronologicalNodes({
  nodes,
  createNewNodeAtStart,
  tagsOrMentions = [],
  containerScrollRef,
  headerRef,
}: {
  nodes: EfNode[];
  createNewNodeAtStart?: () => Promise<EfNodeEditorData | undefined>;
  tagsOrMentions?: string[];
  containerScrollRef: React.RefObject<HTMLElement>;
  headerRef?: React.RefObject<HTMLDivElement>;
}) {
  const [activeNode, setActiveNode] = useState<string | null>(null);
  const nodesPages = useLiveQuery(() => getNodeToPage(nodes), [nodes]);
  const prevNodes = usePrevious(nodes);
  const mobileKeyboardHeight = useIosKeyboardHeight();
  const [initialSortOrder, setInitialSortOrder] = useState<string[]>([]);
  const [createdNewNodeAtStartIds, setCreatedNewNodeAtStartIds] = useState<
    string[]
  >([]);

  const { isKeyboardShortcut } = useKeyboardShortcut({
    keys: [{ or: ["Escape"] }],
    element: window,
  });

  useEffect(() => {
    if (nodes.length && !prevNodes?.length) {
      setInitialSortOrder(
        sortBy(
          nodes,
          (node) =>
            node.clientModifiedTime?.getTime?.() ||
            node.modifiedTime?.getTime?.()
        ).map(({ id }) => id)
      );
    }
  }, [nodes]);

  useEffect(() => {
    const createNewNode = async () => {
      const newNodeToCreate = await createNewNodeAtStart?.();
      if (!newNodeToCreate) {
        return;
      }
      await createNode(newNodeToCreate);
      setCreatedNewNodeAtStartIds([
        ...createdNewNodeAtStartIds,
        newNodeToCreate.id,
      ]);
      setActiveNode(newNodeToCreate.id);
    };
    if (isKeyboardShortcut) {
      createNewNode();
    }
  }, [isKeyboardShortcut]);

  const getPageDetails = (nodeID: string) => {
    if (!nodesPages) {
      return;
    }
    const page = nodesPages[nodeID];
    if (!page) return;
    const pageRoute =
      page.nodeType === EfNodeType.ThoughtPad
        ? `/thoughtpad`
        : `/pages/${page.id}`;
    return {
      route: pageRoute,
      name: page.titleText,
    };
  };

  const filteredAndSortedNodes = useMemo(
    () =>
      filterNodesBasedOnArray(
        filterDeletedNodes(nodes),
        [...initialSortOrder, ...createdNewNodeAtStartIds],
        (a, b) =>
          (a.createdTime?.getTime?.() || 0) - (b.createdTime?.getTime?.() || 0)
      ).reverse(),
    [nodes, initialSortOrder, createdNewNodeAtStartIds]
  );

  if (!nodesPages || isEmpty(nodesPages)) return null;

  return (
    <div
      data-testid={DataTest.GroupedNodesList}
      style={{
        paddingBottom: mobileKeyboardHeight + BOTTOM_TOOLBAR_HEIGHT,
      }}
    >
      {filteredAndSortedNodes.map((node) => {
        const createNewEmptyNode = !!tagsOrMentions.find(
          (tagOrMention) =>
            (node.tagIds || []).includes(tagOrMention) ||
            (node.mentionIds || []).includes(tagOrMention)
        );
        return (
          <div key={node.id} data-testid={DataTest.GroupedNodesListItem}>
            <div className="flex">
              <div className="flex relative">
                <div className="add-padding absolute flex items-start">
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <NavLink
                          to={`${getPageDetails(node.id)?.route}?node=${
                            node.id
                          }`}
                          className="hover:bg-gray-200 p-1.5 rounded-full"
                        >
                          <GoToNodeIcon className="w-5 h-5" />
                        </NavLink>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          {getPageDetails(node.id)?.name}
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
              <div className="w-full pl-8">
                <SingleNodeEditor
                  parentNode={node}
                  isActive={activeNode === node.id}
                  setActive={setActiveNode}
                  createNewEmptyNode={createNewEmptyNode}
                  containerScrollRef={containerScrollRef}
                  headerRef={headerRef}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function filterDeletedNodes(nodes: EfNode[]) {
  return nodes.filter((node) => !node.deleted);
}

async function getNodeToPage(rawNodes: EfNode[]) {
  const pagesById: Record<string, EfNode> = {};

  for (const node of rawNodes) {
    const page = await getParentPageNode(node.id);
    if (page) {
      pagesById[node.id] = page;
    }
  }

  return pagesById;
}
