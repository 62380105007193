import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { EfNode, EfNodeEditorData, ListOrder } from "../types";
import { updateNode } from "../utils";
import { findNodesByParentTag } from "../utils/findNodesByParentTag";
import { findNodesByTag } from "../utils/findNodesByTag";
import { getTagWithFullName } from "../utils/getTagWithFullName";
import {
  NodesGroupedByPage,
  NodesGroupedByPageSkeleton,
} from "./NodesGroupedByPage";
import { ChronologicalNodes } from "./ChronologicalNodes";
import { ListOrderMenu } from "./ListOrder";
import { Menu } from "@headlessui/react";
import { EfNodeType } from "@/graphql";
import { capitalize } from "lodash";
import { BiCheck } from "react-icons/bi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Switch } from "./ui/switch";
import { generateKeyBetween } from "fractional-indexing";
import { v4 } from "uuid";
import { getFirstNodeInAnThoughtPad } from "@/utils/nodes";
import { findNodeDescendents } from "@/utils/findNodeDescendents";
import { useFocusNode } from "@/hooks/useFocusNode";
import { cn } from "@/utils/styles";
import {
  getPagePropertiesInSettings,
  setLastVisitedInSettings,
  setPagePropertiesInSettings,
} from "@/utils/settings";
import { useQuery } from "@/hooks/useQuery";

export const TagRouteWapper = () => {
  const { tagId } = useParams();
  return <TagRoute key={tagId} />;
};

function TagRoute() {
  const { tagId = "" } = useParams();
  const [listMode, setListMode] =
    useState<NonNullable<EfNode["properties"]>["taggedNodesListMode"]>();
  const [descendents, setDescendents] = useState<string[]>([]);
  const [filterTask, setFilterTask] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const stateChangeCountRef = useRef<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const [focusOn, setFocusOn] = useState<boolean>(true);
  const [listOrder, setListOrder] = useState<ListOrder>(
    ListOrder.Chronological
  );

  const [tag, tagLoading] = useQuery(() => getTagWithFullName(tagId), [tagId]);

  useEffect(() => {
    if (!tagLoading) {
      stateChangeCountRef.current += 1;
    }
  }, [tag]);

  const { nodeFocussed, onScroll, observer } = useFocusNode({
    pageId: tagId,
    ids:
      tag?.properties?.taggedNodesListMode === "EXACT" ? [tagId] : descendents,
    enabled: focusOn && !!tag && !!descendents?.length && !!listOrder,
    scrollRef,
    stateChangeCountRef: stateChangeCountRef,
  });

  useEffect(() => {
    findNodeDescendents(tagId).then((data) => {
      setDescendents(data);
    });
  }, [tagId]);

  useEffect(() => {
    if (!tag) return;
    if (listMode) return;
    setListMode(tag.properties?.taggedNodesListMode ?? "HIERARCHICAL");
  }, [listMode, tag]);

  useEffect(() => {
    getPagePropertiesInSettings().then((pageProperties) => {
      setListOrder(
        pageProperties?.[tagId]?.listOrderType || ListOrder.Chronological
      );
    });
  }, []);

  const onChangeListOrder = async (listOrderValue: ListOrder) => {
    setListOrder(listOrderValue);
    setFocusOn(false);
    await setLastVisitedInSettings(tagId, []);
    setPagePropertiesInSettings({ [tagId]: { listOrderType: listOrderValue } });
  };

  const [nodes, nodesLoading] = useQuery(async () => {
    if (!listMode) return;
    const relatedNodes =
      listMode === "HIERARCHICAL"
        ? await findNodesByParentTag(tagId)
        : await findNodesByTag(tagId);
    if (filterTask) {
      return relatedNodes.filter((node) => node.nodeType === EfNodeType.Task);
    }
    return relatedNodes;
  }, [tagId, listMode, filterTask]);

  const changeListMode = (value: typeof listMode) => {
    if (!tag) return;
    setListMode(value);
    setFocusOn(false);
    setLastVisitedInSettings(tagId, []);
    updateNode({
      ...tag,
      properties: {
        ...tag.properties,
        taggedNodesListMode: value,
      },
    });
  };

  const onChangeFilterTask = () => {
    setFilterTask((prevFilterTask) => !prevFilterTask);
    setFocusOn(false);
    setLastVisitedInSettings(tagId, []);
  };

  const createNewNodeAtStart = async () => {
    const { firstNode, thoughtPad } =
      (await getFirstNodeInAnThoughtPad()) || {};
    const node: EfNodeEditorData = {
      id: v4(),
      parentId: thoughtPad?.id!,
      position: generateKeyBetween(null, firstNode?.position || null),
      nodeType: filterTask ? EfNodeType.Task : EfNodeType.Block,
      properties: {
        ...(filterTask && { taskStatus: "PENDING" }),
      },
      tagIds: [tagId],
      referencedPageIds: [],
      mentionIds: [],
      titleText: "",
      contentText: `<p> <span data-type="tag" data-id="${tagId}"></span> </p>`,
    };
    return node;
  };

  // Based on the height of the display getting how many skeleton to show.
  const length = useMemo(() => {
    const iosTopPadding = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--safe-area-inset-top"
      ) || "0"
    );
    return Math.floor((window.innerHeight - 40 - iosTopPadding) / 44) - 1;
  }, []);

  if (!tag || (!nodesLoading && !nodes)) return null;

  return (
    <>
      <div
        className={cn("relative", {
          hidden: nodeFocussed,
          block: !nodeFocussed,
        })}
      >
        <div className="p-2 px-4 absolute w-full">
          <NodesGroupedByPageSkeleton length={length} />
        </div>
      </div>
      <div
        className={cn("overscroll-none overflow-auto p-2 flex-1", {
          "opacity-0": !nodeFocussed,
          "opacity-100": nodeFocussed,
        })}
        onScroll={onScroll}
        ref={scrollRef}
      >
        <div className="max-w-5xl mx-auto mt-4 " ref={observer}>
          <div ref={headerRef}>
            <div className="prose">
              <h1>#{tag.fullName}</h1>
            </div>

            <div className="flex mb-3 items-center justify-between flex-wrap">
              <div className="flex space-x-2 mt-2">
                <ListOrderMenu
                  listOrderOptions={Object.entries(ListOrder)
                    .filter(([_, val]) => val !== ListOrder.ByPriority)
                    .map(([_, val]) => val)}
                  listOrder={listOrder}
                  onChangeListOrder={onChangeListOrder}
                />
                <Menu as="div" className="relative inline-block text-left">
                  {({ open }) => (
                    <>
                      <Menu.Button>
                        <div className="flex justify-between space-x-2 items-center border rounded-md p-1 w-32">
                          <span>{capitalize(listMode)}</span>
                          {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>
                      </Menu.Button>
                      <Menu.Items className="z-50 absolute left-0 mt-2 w-32 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          {["HIERARCHICAL", "EXACT"].map((tagMode) => {
                            return (
                              <Menu.Item key={tagMode}>
                                {({ active }) => (
                                  <button
                                    className={clsx(
                                      {
                                        "hover:bg-gray-100": active,
                                        "pl-5": tagMode !== listMode,
                                      },
                                      "text-gray-900 group flex w-full items-center rounded-md px-1.5 pr-2 py-2 text-sm"
                                    )}
                                    onClick={() => changeListMode(tagMode)}
                                  >
                                    {listMode === tagMode && <BiCheck />}
                                    {capitalize(tagMode)}
                                  </button>
                                )}
                              </Menu.Item>
                            );
                          })}
                        </div>
                      </Menu.Items>
                    </>
                  )}
                </Menu>
              </div>
              <div className="flex space-x-2 mt-2">
                <Switch
                  checked={filterTask}
                  onCheckedChange={onChangeFilterTask}
                  className="data-[state=checked]:bg-[#5577FF] w-[42px] h-[22px]"
                />
                <span>Filter Task</span>
              </div>
            </div>
          </div>

          {listOrder === ListOrder.Chronological && (
            <ChronologicalNodes
              nodes={nodes || []}
              createNewNodeAtStart={createNewNodeAtStart}
              tagsOrMentions={
                tag.properties?.taggedNodesListMode === "HIERARCHICAL"
                  ? descendents
                  : [tagId]
              }
              containerScrollRef={scrollRef}
              headerRef={headerRef}
            />
          )}
          {listOrder === ListOrder.ByPage && (
            <NodesGroupedByPage
              nodes={nodes || []}
              createNewNodeAtStart={createNewNodeAtStart}
              tagsOrMentions={
                tag.properties?.taggedNodesListMode === "HIERARCHICAL"
                  ? descendents
                  : [tagId]
              }
              containerScrollRef={scrollRef}
              headerRef={headerRef}
            />
          )}
        </div>
      </div>
    </>
  );
}
