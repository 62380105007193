import { useEffect, useRef, useState } from "react";
import { db } from "../db";
import { useProxyRef } from "./useProxyRef";

type Action = {
  chunk?: number;
  undo: () => Promise<void>;
  redo: () => void | Promise<void>;
};

const chunkInterval = 1000; // 1 second

export const useHistoryManager = ({
  someNodeIsFocused,
  disabled,
}: {
  someNodeIsFocused: boolean;
  disabled?: boolean;
}) => {
  const lastChunk = useRef(0);
  const lastActionTime = useRef<number>(Date.now());

  const [actions, setActions] = useState<Action[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const canUndo = currentIndex > -1;
  const canRedo = currentIndex < actions.length - 1;

  const run = (action: Action, disableChunk = false) => {
    const actionTime = Date.now();
    if (actionTime - lastActionTime.current > chunkInterval || disableChunk) {
      lastChunk.current++;
    }
    action.chunk = lastChunk.current;
    lastActionTime.current = actionTime;
    // If there are actions after the current index, remove them

    setActions((prevHistory) => {
      const newHistory = [
        ...prevHistory.slice(0, prevHistory.length + 1),
        action,
      ];
      setCurrentIndex(newHistory.length - 1);
      return newHistory;
    });

    return action.redo();
  };

  const undo = () => {
    if (!canUndo) return;

    const currentAction = actions[currentIndex];
    const chunkActions = actions.filter(
      (action) => action.chunk === currentAction.chunk
    );

    db.transaction("rw", db.nodes, async () => {
      await Promise.all(chunkActions.reverse().map((action) => action.undo()));
    });

    setCurrentIndex(currentIndex - chunkActions.length);
  };

  const redo = () => {
    if (!canRedo) return;

    const nextAction = actions[currentIndex + 1];
    const chunkActions = actions.filter(
      (action) => action.chunk === nextAction.chunk
    );

    db.transaction("rw", db.nodes, async () => {
      await Promise.all(chunkActions.map((action) => action.redo()));
    });

    setCurrentIndex(currentIndex + chunkActions.length);
  };

  const undoRef = useProxyRef(undo);
  const redoRef = useProxyRef(redo);

  useEffect(() => {
    if (disabled) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "z") {
        event.preventDefault();
        if (event.shiftKey) {
          redoRef.current();
        } else {
          undoRef.current();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [redoRef, undoRef, disabled]);

  useEffect(() => {
    // we don't want to handle global keydown events if no node is focused, or if the history manager is disabled
    if (!someNodeIsFocused || disabled) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === " " || event.key === "Tab" || event.key === "Enter") {
        lastChunk.current++;
      }
    };

    window.addEventListener("keydown", handleKeyDown, { capture: true });
    return () =>
      window.removeEventListener("keydown", handleKeyDown, { capture: true });
  }, [someNodeIsFocused, disabled]);

  return {
    actions,
    currentIndex,
    run,
    undo,
    redo,
    canUndo,
    canRedo,
  };
};

export type HistoryManager = ReturnType<typeof useHistoryManager>;
