import { useEffect, useMemo } from "react";
import { Button } from "../../ui/button";
import { MdClose } from "react-icons/md";
import { BaseOperatorDropdown } from "../UtilityComponents";
import { useSearch } from "@/context/SearchContext";
import { FIELD_ICONS, Fields, FilterFields, UIFields } from "../fields";
import { AvailableFilter } from "../AvailableFilter";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandList,
} from "@/components/ui/command";
import { TagFilter } from "../Filters/TagFilter";
import { MentionsFilter } from "../Filters/MentionsFilter";
import { DateFilter } from "../Filters/DateFilter";
import { NodeTypeFilter } from "../Filters/NodeTypeFilter";
import { TaskStatusFilter } from "../Filters/TaskStatusFilter";
import {
  FilterContainer,
  FilterItemValueContainer,
} from "../Filters/FilterContainer";
import { isRuleGroup, RuleGroupType } from "react-querybuilder";
import { X } from "lucide-react";
import { format } from "date-fns";
import { FilterRule } from "../types";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import { useDirty } from "./ApplyFilterButton";

export const MobileFilterDrawer = () => {
  const {
    mobileFilterState: { open, field },
    setMobileFilterState,
  } = useSearch();
  const { dirty, reset } = useDirty();

  const renderMenu = () => {
    switch (field) {
      case FilterFields.TagID:
        return <TagFilter dropdown={false} />;
      case FilterFields.MentionID:
        return <MentionsFilter dropdown={false} />;
      case FilterFields.CreatedAt:
        return <DateFilter field={FilterFields.CreatedAt} dropdown={false} />;
      case FilterFields.ModifiedAt:
        return <DateFilter field={FilterFields.ModifiedAt} dropdown={false} />;
      case FilterFields.NodeType:
        return <NodeTypeFilter dropdown={false} />;
      case FilterFields.TaskStatus:
        return <TaskStatusFilter dropdown={false} />;
      default:
        return <FilterMenu dirty={dirty} />;
    }
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        setMobileFilterState({ open: false });
      }}
    >
      <DrawerContent
        className="h-screen"
        style={{
          background: "#F9FAFB",
        }}
      >
        <div className="bg-white h-full">
          <div
            className="flex flex-row flex-1 justify-between items-center px-3"
            style={{
              background: "#F9FAFB",
              paddingTop: "var(--safe-area-inset-top)",
            }}
          >
            <span className="text-lg">Filters</span>
            <Button
              variant={"link"}
              size={"icon"}
              onClick={() => {
                setMobileFilterState({ open: false });
              }}
            >
              <MdClose className="w-4 h-4 sm:w-5 sm:h-5" />
            </Button>
          </div>
          {renderMenu()}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

const FilterMenu = ({ dirty }: { dirty: boolean }) => {
  const { addFilter, filters, setMobileFilterState, clear } = useSearch();
  const availableFilters = useMemo(() => {
    return Object.values(Fields).filter(
      (field) =>
        !filters.rules.find((rule) => rule.id === field.name) &&
        UIFields.includes(field.name)
    );
  }, [filters?.rules]);

  return (
    <div className="flex flex-col justify-center mt-2 gap-2 p-3">
      <BaseOperatorDropdown
        dirty={dirty}
        className={dirty ? "justify-between" : undefined}
      />
      {filters.rules.length > 0 && (
        <>
          <div className="flex justify-between">
            <span className="font-bold text-xl">Active Filters</span>
            <Button
              variant="link"
              className="flex flex-row gap-1 h-[28px] text-gray-500 pr-0"
              onClick={() => clear()}
            >
              Clear
            </Button>
          </div>
          {filters.rules.map((rule) => (
            <BasicActiveFilter rule={rule} key={rule.id} />
          ))}
        </>
      )}
      {availableFilters.length > 0 && (
        <>
          <span className="font-bold text-xl">Available Filters</span>
          <Command>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              {availableFilters.map((field) => (
                <AvailableFilter
                  field={field}
                  key={field.id}
                  addFilter={(rule) => {
                    if (!filters.rules.find((rule) => rule.id === field.name)) {
                      addFilter(rule);
                    }
                    setMobileFilterState({
                      open: true,
                      field: field.name,
                    });
                  }}
                />
              ))}
            </CommandList>
          </Command>
        </>
      )}
    </div>
  );
};

interface BasicActiveFilterProps {
  rule: FilterRule | RuleGroupType<FilterRule, string>;
}
const BasicActiveFilter = ({ rule }: BasicActiveFilterProps) => {
  const { removeRule, setMobileFilterState } = useSearch();
  const field = Fields.find((f) => f.name === rule.id);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = FIELD_ICONS[rule.id] as (() => JSX.Element) | undefined;

  const getFilterValue = () => {
    if (isRuleGroup(rule)) {
      return (
        <FilterItemValueContainer>
          <span className="text-xs">
            {rule.rules.length > 0 ? rule.rules.length : "No"} values
          </span>
        </FilterItemValueContainer>
      );
    }
    if (
      rule.id === FilterFields.CreatedAt ||
      rule.id === FilterFields.ModifiedAt
    ) {
      const [date1, date2] = rule.value.split(",") as unknown as [
        Date | undefined,
        Date | undefined
      ];
      return (
        <FilterItemValueContainer>
          <span className="text-xs">
            {date1 && format(date1, date2 ? "MMMM do" : "PPP")}
            {!date1 && "No value"}
          </span>
          <span className="text-xs">
            {date2 && `-${format(date2, "MMMM do")}`}
          </span>
        </FilterItemValueContainer>
      );
    }
    return field?.value ? field.value : "No value";
  };

  const getOperator = () => {
    if (isRuleGroup(rule)) {
      return rule.combinator === "and" ? "all of" : "any of";
    }
    return rule.operator;
  };
  const operator = getOperator();

  const label: string = (field?.shortenedLabel as string) || field?.label || "";

  return (
    <FilterContainer
      onClick={() => {
        setMobileFilterState({ open: true, field: rule.id as FilterFields });
      }}
    >
      <div className="flex flex-1 flex-row justify-between gap-2">
        <div className="flex flex-row gap-1">
          {Icon && <Icon />}
          <span>{label}</span>
          {operator && <span>{operator}</span>}
          {getFilterValue()}
        </div>
        <Button
          variant="outline"
          size="icon"
          onClick={(e) => {
            e.stopPropagation();
            removeRule(field?.name as string);
          }}
          className="h-5 w-5 border-0"
        >
          <X height={16} width={16} />
        </Button>
      </div>
    </FilterContainer>
  );
};
