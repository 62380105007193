import { FIELD_ICONS, FilterFields } from "../fields";
import { FilterItemValueContainer } from "./FilterContainer";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback } from "react";
import { queryMentions } from "@/extensions/Mention";
import { db } from "@/db";
import { EfNode } from "@/types";
import { GenericFilter } from "./GenericFilter";

interface MentionsFilterProps {
  dropdown?: boolean;
}
export function MentionsFilter({ dropdown = true }: MentionsFilterProps) {
  const getMentions = useCallback(async (query: string) => {
    const mentions = await queryMentions(query);
    return mentions.map((mention) => ({
      label: mention.label || mention.id,
      value: mention.id,
    }));
  }, []);

  const Icon = FIELD_ICONS[FilterFields.MentionID];

  return (
    <GenericFilter
      field={FilterFields.MentionID}
      getItems={getMentions}
      Icon={Icon}
      itemRenderer={(itemsIds) => <Mentions mentionIds={itemsIds} />}
      title="Mentions include"
      commandPlaceholder="Search for Mention"
      dropdown={dropdown}
    />
  );
}

interface MentionsProps {
  mentionIds: string[];
}
const Mentions = ({ mentionIds }: MentionsProps) => {
  const mentions = useLiveQuery(
    () => Promise.all(mentionIds.map((mentionId) => db.nodes.get(mentionId))),
    [mentionIds]
  )?.filter(Boolean) as EfNode[] | undefined;
  if (!mentions) return null;

  return (
    <FilterItemValueContainer>
      {mentions.length === 0 && "No mentions"}
      {mentions.length === 1 && mentions[0].titleText}
      {mentions.length > 1 && `${mentions.length} mentions`}
    </FilterItemValueContainer>
  );
};
