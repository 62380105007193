import { FilterFields } from "@/components/SearchFilter/fields";
import {
  SearchRuleGroupType,
  FilterRule,
} from "@/components/SearchFilter/types";
import { db } from "@/db";
import { api } from "@/graphql";
import { EfNode, ListOrder } from "@/types";
import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { formatQuery, isRuleGroup } from "react-querybuilder";
import { useLocation, useSearchParams } from "react-router-dom";

interface NodesInfo {
  nodes: EfNode[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalNodes: number;
}

interface SearchContextValue {
  inSearch: boolean;
  loading: boolean;
  nodesPerPage: number;
  setNodesPerPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCursors: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  nodesInfo: NodesInfo;
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  cleanFilters: string;
  filters: SearchRuleGroupType;
  setFilters: React.Dispatch<React.SetStateAction<SearchRuleGroupType>>;
  fetchNodes: (
    currPage: number,
    nodesToFetch?: number,
    forceSearchValue?: string,
    forceSearch?: boolean
  ) => Promise<void>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  listOrder: ListOrder;
  setListOrder: React.Dispatch<React.SetStateAction<ListOrder>>;
  mobileFilterState: { open: boolean; field?: FilterFields };
  setMobileFilterState: React.Dispatch<
    React.SetStateAction<{ open: boolean; field?: FilterFields }>
  >;
  clear: () => void;
}

export const SearchContext = createContext<SearchContextValue>(null!);

const initialCursors = {
  0: "",
  1: "",
};
const initialNodesInfo = {} as NodesInfo;
export function SearchContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchType = searchParams.get("searchType") || "text";
  const [nodesPerPage, setNodesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [cursors, setCursors] =
    useState<Record<number, string>>(initialCursors);
  const inSearch = useMemo(() => location.pathname === "/search", [location]);
  const [nodesInfo, setNodesInfo] = useState<NodesInfo>(initialNodesInfo);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [listOrder, setListOrder] = useState(
    isMobile ? ListOrder.ByPage : ListOrder.Chronological
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("query") ?? ""
  );
  const prevFullQuery = useRef<string | undefined>(undefined);
  const prevSearchDate = useRef<number>(0);
  const [filters, setFilters] = useState<SearchRuleGroupType>(
    getEmptyFiltersField()
  );
  const [mobileFilterState, setMobileFilterState] = useState<{
    open: boolean;
    field?: FilterFields;
  }>({
    open: false,
  });

  const cleanFiltersObj = useMemo(() => cleanFilterGroup(filters), [filters]);
  // We also keep track of cleanFilters string, that is used in a use effect dependency to only trigger search once a new filter is added/value is changed
  const cleanFilters = useMemo(
    () =>
      cleanFiltersObj ? formatQuery(cleanFiltersObj, "json_without_ids") : "",
    [cleanFiltersObj]
  );

  const fetchNodes = async (
    currPage: number,
    nodesToFetch?: number,
    forceSearchValue?: string,
    forceSearch?: boolean
  ) => {
    const textQuery =
      forceSearchValue !== undefined ? forceSearchValue : searchValue;
    const queryToSearch = createFullQuery(textQuery, cleanFiltersObj);
    if (!forceSearch) {
      if (!inSearch) return;
      if (!cleanFiltersObj?.rules.length && !textQuery) {
        // If there is no query clear the results
        setNodesInfo(initialNodesInfo);
        setCursors(initialCursors);
        prevFullQuery.current = undefined;
        return;
      }
      const timeSinceLastSearch = Date.now() - prevSearchDate.current;
      if (prevFullQuery.current === queryToSearch && timeSinceLastSearch < 1000)
        // Only do no re-search same query if it has been searched less than a second ago
        return console.log("Identical query"); // Do no query same query again
    }
    prevFullQuery.current = queryToSearch;
    prevSearchDate.current = Date.now();
    setLoading(true);

    try {
      const result = await api.SearchText({
        first: nodesToFetch ?? nodesPerPage,
        searchType: searchType,
        query: "",
        searchFilter: queryToSearch,
        after: cursors[currPage - 1]!,
      });
      if (result.data.search.__typename === "EFNodeConnection") {
        const { pageInfo, edges } = result.data.search;
        setCursors((prevCursors) => {
          const newCursor = {
            ...prevCursors,
            [currPage]: pageInfo.endCursor!,
          };
          return newCursor;
        });
        setNodesInfo({
          nodes: await getNodesFromDB(
            edges
              .map((edge) => edge.node.id as string)
              .filter(Boolean) as string[]
          ),
          hasNextPage: pageInfo.hasNextPage,
          hasPreviousPage: pageInfo.hasPreviousPage,
          totalNodes: pageInfo.totalNodes || 0,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Clear search filters (not including search value)
   */
  const clear = () => {
    setFilters(getEmptyFiltersField());
    setCursors(initialCursors);
    setNodesInfo(initialNodesInfo);
    prevFullQuery.current = undefined;
  };

  return (
    <SearchContext.Provider
      value={{
        loading,
        currentPage,
        nodesPerPage,
        setCurrentPage,
        setNodesPerPage,
        inSearch,
        setCursors,
        nodesInfo,
        showFilters,
        setShowFilters,
        cleanFilters,
        filters,
        setFilters,
        searchValue,
        setSearchValue,
        fetchNodes,
        listOrder,
        setListOrder,
        mobileFilterState,
        setMobileFilterState,
        clear,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const searchContext = useContext(SearchContext);

  const addFilter = (rule: SearchRuleGroupType["rules"][number]) => {
    searchContext.setFilters((currentFilter) => {
      return {
        ...currentFilter,
        rules: [
          ...currentFilter.rules,
          {
            ...rule,
          },
        ],
      };
    });
  };

  const setRule = (rule: SearchRuleGroupType["rules"][number]) => {
    searchContext.setFilters((currentFilter) => {
      return {
        ...currentFilter,
        rules: currentFilter.rules.map((r) => {
          if (r.id === rule.id) {
            return rule;
          }
          return r;
        }),
      };
    });
  };

  const removeRule = (ruleId: string) => {
    searchContext.setFilters((currentFilter) => {
      return {
        ...currentFilter,
        rules: currentFilter.rules.filter((r) => r.id !== ruleId),
      };
    });
  };

  return {
    ...searchContext,
    addFilter,
    setRule,
    removeRule,
  };
}

export const getContentAndTitleRule = (
  query?: string
): SearchRuleGroupType | undefined => {
  if (!query) return undefined;
  return {
    id: FilterFields.ContentAndTitle,
    combinator: "or",
    rules: [
      {
        id: FilterFields.Content,
        field: FilterFields.Content,
        operator: "contains",
        value: query,
      },
      {
        id: FilterFields.Title,
        field: FilterFields.Title,
        operator: "contains",
        value: query,
      },
    ],
  };
};

const getEmptyFiltersField = (): SearchRuleGroupType => ({
  id: FilterFields.Filters,
  combinator: "and",
  rules: [],
});

const getNodesFromDB = async (ids: string[]) =>
  (await db.nodes.bulkGet(ids)).filter(Boolean) as EfNode[];

const cleanFilterGroup = (
  group: SearchRuleGroupType
): SearchRuleGroupType | null => {
  // Filter and map to create a new structure
  const filteredRules = group.rules.reduce((acc, rule) => {
    if (isRuleGroup(rule)) {
      // It's a FilterGroup, recursively clean it
      const cleanedGroup = cleanFilterGroup(rule);
      if (cleanedGroup !== null) {
        // If the cleaned group is not null, push a new group to the accumulator
        acc.push({ ...cleanedGroup });
      }
    } else {
      // It's a FilterRule, check if the value is not falsy
      if (rule.value !== undefined && !!rule.value) {
        // Since it's valid, push a new object to the accumulator
        acc.push({ ...rule });
      }
    }
    return acc;
  }, [] as Array<FilterRule | SearchRuleGroupType>);

  // If the filtered list of rules is empty, return null to indicate this group should be removed
  if (filteredRules.length === 0) {
    return null;
  }

  // Return a new FilterGroup with the cleaned list of rules
  return { ...group, rules: filteredRules };
};

const createFullQuery = (
  searchValue: string | undefined,
  cleanFilters: SearchRuleGroupType | null
) => {
  const searchQuery = getContentAndTitleRule(searchValue);
  const cleanQueryObj = searchQuery ? cleanFilterGroup(searchQuery) : undefined;
  const queryObj = {
    combinator: "and",
    rules: [cleanQueryObj, cleanFilters].filter(
      Boolean
    ) as SearchRuleGroupType["rules"],
  };
  return formatQuery(queryObj, "json_without_ids");
};
