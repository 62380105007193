import { useSearch } from "@/context/SearchContext";
import { FilterFields } from "../fields";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";

export const ApplyFiltersButton = () => {
  const { setMobileFilterState } = useSearch();

  return (
    <Button
      variant={"secondary"}
      onClick={() => {
        setMobileFilterState({ open: false });
      }}
    >
      Apply
    </Button>
  );
};

export const useDirty = (field?: FilterFields) => {
  const { filters } = useSearch();
  const rule = useMemo(
    () => filters.rules.find((rule) => rule.id === field),
    [filters, field]
  );
  const [dirtyCount, setDirtyCount] = useState(0);
  const dirty = dirtyCount > 1;

  useEffect(() => {
    // Case for 3rd level
    if (dirty || !field) return;
    setDirtyCount((prev) => prev + 1);
  }, [rule, dirty, field]);

  useEffect(() => {
    if (dirty || field) return;
    setDirtyCount((prev) => prev + 1);
  }, [filters]);

  const reset = () => setDirtyCount(1);

  return { dirty, reset };
};
