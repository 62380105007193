import { useSearch } from "@/context/SearchContext";
import { OperatorDropdown } from "./OperatorDrowdown";
import { cn } from "@/utils/styles";
import { ApplyFiltersButton } from "./MobileFilterDrawer/ApplyFilterButton";

interface BaseOperatorDropdownProps {
  dirty?: boolean;
  className?: string;
}
export const BaseOperatorDropdown = ({
  dirty,
  className,
}: BaseOperatorDropdownProps) => {
  const { filters, setFilters } = useSearch();
  return (
    <div className={`flex flex-row gap-2 items-center ${className}`}>
      Show Results Including
      <OperatorDropdown
        operator={filters.combinator === "or" ? "any of" : "all of"}
        operators={[
          {
            label: "all of",
            value: "all of",
          },
          {
            label: "any of",
            value: "any of",
          },
        ]}
        setOperator={(operator) => {
          setFilters({
            ...filters,
            combinator: operator === "any of" ? "or" : "and",
          });
        }}
      />
      Filters
      {dirty && <ApplyFiltersButton />}
    </div>
  );
};
